/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

 import React, { useState, useEffect } from "react"
 import PropTypes from "prop-types"
 import { Row, Col, Button, Form } from 'react-bootstrap';
 import { filterNumber, numberFormat, pmt } from "./util"
 import { defaultValues } from "./config"
 // use project specific import here
 import {calculateMonthlyPayment} from "../mortgage"
 
// User project specific const
const durationOptions = [10, 15, 20, 25]

// DO NOT DO ANY CHNAGES - START
const MortgageCalcProperty = props => {
  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    title,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props

    const [monthlyPayment, setMonthlyPayment] = useState(0);

  const prefix = pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(
    numberFormat(propertyPrice)
  )
  let dep_price = (propertyPrice/100)*20
  const [deposit, setDeposit] = useState(numberFormat(dep_price))
  const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")
  const [errormsg, setErrorMsg] = useState("* All fields are required")

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(numberFormat(val))
    if (parseInt(filterNumber(deposit)) && parseInt(val)) {
      let loan = parseInt(val) - parseInt(filterNumber(deposit))
      setLoan(numberFormat(loan))
    } else {
      setLoan(0)
    }
  }

  const handleDeposit = event => {
    let d = filterNumber(event.target.value.replace(/[^\d.]/g, ""))
    setDeposit(numberFormat(d))

    if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
      let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
      setLoan(numberFormat(loan2))
    } else {
      setLoan(0)
    }
  }

  const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
  }

  const handleInterest = event => {
    setInterest(event.target.value.replace(/[^\d.]/g, ""))
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (interest, duration, deposit, loan) => {
    let result = calculateMonthlyPayment(propertyPrice, interest, filterNumber(deposit), duration);
    setResult(numberFormat(Math.round(result)))

  }

  const [depositError, setDepError] = useState(false)
  const [intError, setIntError] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Calculator',
        'formType': title,
        'formId': "Mortgage Calculator",
        'formName': "Mortgage Calculator",
        'formLabel': "Mortgage Calculator"
      });
      setShowerror(false)
      setValidated(false)
      var par = purchasePrice.replace('AED', '').replace(/,/g, '')
      var dep = deposit.replace('AED', '').replace(/,/g, '')
      var par1 = parseInt(par)
      var dep1 = parseInt(dep)

      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
        if (interest === "0") {

        }
        else if (par1 < dep1) {
          setShowerror(true)
          setDepError(true)
          setIntError(true)
          setErrorMsg("Please enter deposit amount less than the property amount.")
          setResult("")
        }
        else if (par1 == dep1) {
          setShowerror(true)
          setDepError(false)
          setIntError(true)
          setErrorMsg("Please enter deposit amount less than the property amount.")
          setResult("")
        }
        else {
          setShowerror(false)
          setDepError(false)
          getResult(interest, duration, deposit, loan)


        }

      }
      else if (dep1 == 0) {
        setShowerror(true)
        setDepError(false)
        setIntError(false)
        setResult("")
      }
      else if (par1 == 0) {
        setShowerror(true)
        setDepError(false)
        setIntError(false)
        setResult("")
      }

    }
  }
 
   useEffect(() => {
    // if (defaultResult) {
    if (
      parseInt(filterNumber(purchasePrice)) &&
      parseInt(filterNumber(deposit))
    ) {
      let loan =
        parseInt(filterNumber(purchasePrice)) -
        parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    }

    // getResult(interest, duration, loan)
    let depositeAmt = parseInt(filterNumber(deposit));
    if (depositeAmt > 0 && filterNumber(interest) > 0) {
      if (propertyPrice < depositeAmt) {
        setShowerror(true)
        setDepError(true)
        setIntError(true)
        setErrorMsg("Please enter deposit amount less than the property amount.")
        setResult("")
      } 
      else if(depositeAmt === propertyPrice) {
        setShowerror(true)
        setDepError(true)
        setIntError(true)
        setErrorMsg("Please enter deposit amount less than the property amount.")
        setResult("")
      }   
      else {
        setShowerror(false)
        setDepError(false)
        setIntError(false)
        getResult(interest, duration, deposit, loan)
        setTimeout(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'Calculator',
            'formType': title,
            'formId': "Mortgage Calculator",
            'formName': "Mortgage Calculator",
            'formLabel': "Mortgage Calculator"
          });
        }, 1000)

      }
    } 
    else if(depositeAmt == 0) {
      setShowerror(true)
      setDepError(true)
      setIntError(true)
      setErrorMsg("Please enter deposit amount less than the property amount.")
      setResult("")
    }    
    else if(filterNumber(interest) == 0) {
      setShowerror(true)
      setDepError(true)
      setIntError(true)
      setErrorMsg("Please enter deposit amount less than the property amount.")
      setResult("")
    }     
    else {
      setShowerror(true)
      setDepError(false)
      setIntError(false)
      setResult("")
    }
    // }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])
 
   // DO NOT DO ANY CHNAGES - END
   var monthly_payment = Math.round(monthlyPayment);
 
   return (
     <div className="form mortgate-form calculator">
       {showerror && (
         <div className="alert-danger">
           <p>{errormsg}</p>
         </div>
       )}
       <Form
         name="MortgageCalc"
         noValidate
         validated={validated}
         onSubmit={handleSubmit}
       >
         <input type="hidden" name="bot-field" />
 
       <Row>
 
         <Col md={3} className="mortg">
           <Form.Group controlId="purchase_price">
             <div className="custom-float">
               <Form.Label>Property Price:</Form.Label>
               <Form.Control 
                 required
                 type="text"
                 name="purchase_price"
                 value={purchasePrice}
                 placeholder="Purchase Price"
                 onChange={handlePrice}
                 disabled
               />
             </div>
           </Form.Group>
         </Col>
 
 
         <Col md={3} className="mortg">
           <Form.Group controlId="deposit">
             <div className="custom-float">
               <Form.Label>Deposit:</Form.Label>
               <Form.Control 
                 required
                 type="text"
                 name="deposit_available"
                 placeholder="Deposit"
                 value={deposit}
                 onChange={handleDeposit}
               />
             </div>
           </Form.Group>
         </Col>
 
 
         <Col md={3} className="mortg">
           <Form.Group controlId="interest_rate">
             <div className="custom-float">
               <Form.Label>Annual Interest:(%)</Form.Label>
               <Form.Control 
                 required
                 type="text"
                 name="interest_rate"
                 value={interest}
                 placeholder="Interest Rate (%)"
                 onChange={handleInterest}
               />
             </div>
           </Form.Group>
         </Col>
 
 
         <Col md={3} className="mortg">
           <Form.Group controlId="duration">
             <div className="custom-float custom-float--select property-details-custom-select">
               <Form.Label>Repayment Period:</Form.Label>
               <Form.Control 
                 as="select"
                 className="form-control"
                 required
                 name="duration"
                 value={duration}
                 placeholder="Duration (Years)"
                 onChange={handleDuration}
               >
                 {durationOptions.map((value, key) => {
                   return (
                     <option value={value} key={key}>
                       {value} Years
                     </option>
                   )
                 })}
               </Form.Control>
             </div>
           </Form.Group>
         </Col>
 
         <Col md={12}>
            {result && (
                <div className="total">
                    <h2>Monthly Repayments:</h2>
                    <span className="price">{currency} {result}</span>
                </div>
              )}
         </Col>
 
 
       </Row>
       </Form>
       
     </div>
   )
 }
 
 MortgageCalcProperty.defaultProps = {
   propertyPrice: defaultValues.PROPERTY_VALUE,
   depositAmount: defaultValues.DEPOSIT,
   loadDuration: defaultValues.DURATION,
   interestRate: defaultValues.INTEREST,
   currency: process.env.CURRENCY
     ? process.env.CURRENCY
     : defaultValues.CURRENCY,
   defaultResult: defaultValues.DEFAULT_RESULT,
   pricePrefix: defaultValues.PRICE_PREFIX,
   // instantResult: defaultValues.INSTANT_RESULT,
   // durationSuffix: defaultValues.DURATION_SUFFIX,
   // yearSuffix: defaultValues.YEAR_SUFFIX,
   // yearSelect: defaultValues.YEAR_SELECT,
 }
 
 MortgageCalcProperty.propTypes = {
   propertyPrice: PropTypes.any,
   depositAmount: PropTypes.any,
   loadDuration: PropTypes.any,
   interestRate: PropTypes.any,
   currency: PropTypes.string,
   defaultResult: PropTypes.any,
   pricePrefix: PropTypes.any,
   // instantResult: PropTypes.any,
   // durationSuffix: PropTypes.any,
   // yearSuffix: PropTypes.any,
   // yearSelect: PropTypes.any
 }
 
 export default MortgageCalcProperty
 