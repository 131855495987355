import React from 'react';
import { useQuery } from '@apollo/client';
import { Container, Row, Col } from "react-bootstrap"
import gql from "graphql-tag"
import { Link } from "gatsby"
import Helmet from 'react-helmet'

import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ErrorPage from '../pages/PropertyDetails404'

import TopBar from "../components/PropertyDetails/TopBar/TopBar";
import Banner from "../components/PropertyDetails/DetailsBanner/Banner";
import ViewPage from "../components/PropertyDetails/ViewPage/ViewPage";
import Description from "../components/PropertyDetails/Description/Description";
import RoomDetails from "../components/PropertyDetails/RoomDetails/RoomDetails";
import Owner from "../components/PropertyDetails/Owner/Owner";
import Payment from "../components/PropertyDetails/Payment/Payment";
import Info from "../components/PropertyDetails/Info/Info";
import SimilarProperty from "../components/PropertyDetails/Property/SimilarProperty";
import logoBlack from "../images/logo-dark.svg";
import PropertyStructuredData from "../components/Seo/PropertyStructuredData"
import LoadExternalScript from "../components/utils/load-external-script";

import { BRANCHES_PAGE_URL } from "../components/common/site/constants";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{crm_id: $id, publish:true}) {
      id
      title
      crm_id
      display_address
      search_type
      building
      department
      area
      status
      price
      images
      imagetransforms
      floorplan
      virtual_tour
      brochure
      latitude
      longitude
      bedroom
      bathroom
      reception
      room_details
      description
      accommodation_summary
      long_description
      crm_negotiator_id
      office_crm_id
      features
      address
      extra
      related_properties {
        id
        images
        imageCount
        imagetransforms
        search_type
        crm_id
        slug
        display_address
        address
        price
        title
        bedroom
        bathroom
        reception
        virtual_tour
        floorplan
      }
    }
  }
`;

const locRatingScriptsJs = 'https://www.locrating.com/scripts/locratingIntegrationScripts.js';
const _firstMarkerImage = '.'
const renderMap = () => {
    if (typeof window !== 'undefined') {
        const _baseUrl = window.location.origin;
        if (typeof window.loadLocratingPlugin !== 'undefined') {
            window.loadLocratingPlugin({
                id: "property-location-map",
                lat: 51.30965,
                lng: 1.12385,
                // starttab: props.mapview ? props.mapview : 'location',
                type: "transport",
                icon: _firstMarkerImage,
                zoom: 13,
                mapstyle: 'voyager',
                hidestationswhenzoomedout: true,
            })
        }
    }

}

const PropertyDetailsTemplate = (props) => {

  // 
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");

  const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id }, context: { clientName: "feed" }
  });
  // 
  if (loading) return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
        </div>
      </div>
    </section>
  );



  return (
    <React.Fragment>
      {property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {
        const metaTitle = data.title + ' in ' + data.address?.display_address
        var sea_type = data.search_type == 'sales' ? 'for sale' : 'to rent'
        const metaDesc = process.env.GATSBY_SITENAME+' has a ' + data.bedroom + ' Bedroom ' + data.building +' '+ sea_type+ ' in ' + data.address?.display_address +'.' + ' View the ' + data.bedroom + ' bedroom property floor plan, photos and map of the local area.'

        return (
          <>
            <Helmet
              bodyAttributes={{
                class: 'property-details-page'
              }}
            />
            <LoadExternalScript
                src={locRatingScriptsJs}
                // async="true"
                defer="true"
                loadScript={renderMap}
            // appendScriptTo="head"
            />
            <PropertyStructuredData
              property={data}
              pageurl={pageurl}
            />
            <div className="wrapper property-details-wrapper">
              <Header />
              <SEO title={metaTitle} description={metaDesc} image={data.images[0].url}/>
              <TopBar />
              <Banner propertyData={data} />
              <ViewPage propertyData={data} />
              <SimilarProperty propertyData={data} />
              <Container>
                <Row>
                  <Col>
                    <div className='property-details-footer-content'>
                      <p>The information provided about this property does not constitute or form part of an offer or contract, nor may be it be regarded as representations. All interested parties must verify accuracy and your solicitor must verify tenure/lease information, fixtures & fittings and, where the property has been extended/converted, planning/building regulation consents. All dimensions are approximate and quoted for guidance only as are floor plans which are not to scale and their accuracy cannot be confirmed. Reference to appliances and/or services does not imply that they are necessarily in working order or fit for the purpose.</p>
                      <p>We are pleased to offer our customers a range of additional services to help them with moving home. None of these services are obligatory and you are free to use service providers of your choice. Current regulations require all estate agents to inform their customers of the fees they earn for recommending third party services. If you choose to use a service provider recommended by Wards, details of all referral fees can be found at the link below. If you decide to use any of our services, please be assured that this will not increase the fees you pay to our service providers, which remain as quoted directly to you.</p>
                      <p>*Savings on the property value range from 8.5% to 59% and depend on the customer’s age, personal circumstances, and property criteria. Only available to those aged sixty years old and over.</p>
                      <p>**This rent is for illustrative purposes only and is representative of achievable rent. Please <Link to={BRANCHES_PAGE_URL.alias}><u>call your local office</u></Link> for details.</p>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Footer search="propertydetails" propertydetails={data} />
            </div>
          </>
        )
      }) : <ErrorPage />}
    </React.Fragment>
  )
}

export default PropertyDetailsTemplate;
